import {Col, Container, Row} from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import {useTheme} from "../../theme/theme_context";
import {useEffect, useState} from "react";
import {backend_url} from "../../global_values";
import {MarketSeriesInterface} from "../market_datachart_interface";
import Dropdown from 'react-bootstrap/Dropdown';
import {useParams} from "react-router";
import {
    default_market_candlestick_quantity,
    default_market_candlestick_time_frame,
    error_market_name,
    error_resource_name,
    existing_markets
} from "../const_values";
import {all_resources} from "../../calculators/values/armor_constants";
import {capitalizeFirstLetter} from "../../../utilities/strings";
import "../market.css"

function get_market_name(market_params_value: string|undefined) {
    if (market_params_value === undefined) {
        return error_market_name;
    }
    if (existing_markets.includes(market_params_value)) {
        return market_params_value;
    }
    return error_market_name;
}

function get_resource_name(resource_params_value: string|undefined) {
    if (resource_params_value === undefined) {
        return error_resource_name;
    }
    const capitalised = capitalizeFirstLetter(resource_params_value);
    if (all_resources.some((material) => material.name === capitalised)) {
        return resource_params_value;
    }
    return error_resource_name;
}

function ResourceMarket() {
    const { theme } = useTheme();
    const resource_name_params = useParams().resource_name;
    const market_name_params = useParams().market_name;
    const market_name = get_market_name(market_name_params);
    const resource_name = get_resource_name(resource_name_params);

    const [rawData, setRawData] = useState([]);
    const [displayedData, setDisplayedData] = useState<MarketSeriesInterface[]>([]);
    const [timeFrame, setTimeFrame] = useState(default_market_candlestick_time_frame);
    const [quantity, setQuantity] = useState(default_market_candlestick_quantity)

    useEffect(() => {
        if(resource_name !== error_resource_name && market_name !== error_market_name) {
            fetch(backend_url + "/" + market_name + "/" + resource_name + "?time_frame=" + encodeURIComponent(timeFrame))
                .then(response => response.json())
                .then(fetchedData => {
                    const buffer = fetchedData.map((item: any) => ({
                        x: new Date(item.date),
                        y: [item.open, item.high, item.low, item.close]
                    }));
                    setRawData(buffer);
                })
                .catch(error => console.error('Error:', error));
        }
    }, [timeFrame, resource_name, market_name]);

    useEffect(() => {
        const toInsert = [{
            data: rawData.slice(-quantity)
        }]
        setDisplayedData(toInsert);
    }, [rawData, quantity]);

    let options: ApexOptions = {
        chart: {
            type: 'candlestick',
            height: 350
        },
        grid: {
            yaxis: {
                lines: {
                    show: false, // Disable horizontal grid lines
                },
            },
        },
        title: {
            text: 'CandleStick Chart',
            align: 'left',
            style: {
                color: theme === "dark" ? "#ffffff" : "#000000"
            }
        },
        xaxis: {
            type: 'datetime',
            title: {
                style: {
                    color: theme === "dark" ? "#ffffff" : "#000000"
                }
            },
            labels: {
                style: {
                    colors: theme === "dark" ? "#ffffff" : "#000000"
                }
            },
        },
        yaxis: {
            title: {
                style: {
                    color: theme === "dark" ? "#ffffff" : "#000000"
                }
            },
            labels: {
                style: {
                    colors: theme === "dark" ? "#ffffff" : "#000000"
                }
            },
            tooltip: {
                enabled: true
            }
        },
        tooltip: {
            theme: theme,
        }
    }

    function display_error() {
        return (
            <Row>
                <p>
                    {
                        resource_name === error_resource_name
                        ? "Error: Resource '" + resource_name + "' not found"
                        : ""
                    }
                </p>
                <p>
                    {
                        market_name === error_market_name
                        ? "Error: Market name '" + market_name + "' not found"
                        : ""
                    }
                </p>
            </Row>
        );
    }

    function display_market() {
        return <>
            <Row>
                <h1>{capitalizeFirstLetter(resource_name)} at {capitalizeFirstLetter(market_name)}</h1>
            </Row>
            <Row>
                <Col md={6}>
                    <Container>
                        <Row className={"market-text-align-center market-small-padding-under"}>
                            <h2>
                                Settings
                            </h2>
                        </Row>
                        <Row>
                            <Col md={6}>
                                This will change the amount of time for one candlestick
                            </Col>
                            <Col md={6}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        timeframe : {timeFrame}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setTimeFrame("15 minutes")}>15 minutes</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setTimeFrame("30 minutes")}>30 minutes</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setTimeFrame("1 hour")}>1 hour</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setTimeFrame("4 hours")}>4 hours</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setTimeFrame("1 day")}>1 day</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row className={"market-small-padding-under"}>
                            <Col md={6}>
                                This will change the amount of candlesticks displayed
                            </Col>
                            <Col md={6}>
                                <input type="number" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))}/>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={6}>
                    <Container>
                        <Row className={"market-text-align-center market-small-padding-under"}>
                            <h2>
                                Current values
                            </h2>
                        </Row>
                        {/*<Row>*/}
                        {/*    Current price:*/}
                        {/*</Row>*/}
                        {/*<Row>*/}
                        {/*    Current quantity: {quantity}*/}
                        {/*</Row>*/}
                    </Container>
                </Col>
            </Row>
            <Row>
                <ReactApexChart options={options} series={displayedData} type="candlestick" height={350} />
            </Row>
        </>
    }

    return (
        <Container>
            {
                resource_name === error_resource_name || market_name === error_market_name
                ? display_error()
                : display_market()
            }
        </Container>
    );
}

export default ResourceMarket;
