import {all_resources, material} from "../calculators/values/armor_constants";
import {Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {backend_url} from "../global_values";
import {MarketData} from "./interfaces/market_data";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import {isOlderThan} from "../../utilities/is_older_than";
import {market_no_longer_for_sale_metric, market_no_longer_for_sale_value} from "./const_values";
import {GlobalMarketValues} from "./interfaces/global_market_values";
import MarketValueTable from "./market_values_table";

dayjs.extend(utc);
dayjs.extend(relativeTime);

function GlobalMarket() {
    const resources = all_resources
    const market_name = "origin";
    const market_url = backend_url + "/" + market_name;
    const [marketData, setMarketData] = useState<MarketData>({});
    const [marketValues, setMarketValues] = useState<GlobalMarketValues[]>([]);
    const [marketLinks, setMarketLinks] = useState<string[]>([]);
    // const [lastRequestTime, setLastRequestTime] = useState<string>("");

    const market_headers = [
        "Icon",
        "Name",
        "Price",
        "Quantity",
        "Last updated ( local time )"
    ];

    function fetchMarketData() {
        fetch(market_url)
            .then(response => response.json())
            .then(fetchedData => {
                setMarketData(fetchedData);
            })
            .catch(error => console.error('Error:', error));
            // need to store the values in the state
            // need to check the time of the last update and prevent more than 1 request per 30 seconds ( to be defined in a constant )
    }

    useEffect(() => {
        fetchMarketData();
        const interval = setInterval(() => {
            fetchMarketData();
            // setLastRequestTime(dayjs(new Date()).local().fromNow());
        }, 60000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    function no_resources_available(resource: material, last_available: string): GlobalMarketValues {
        return {
            source_asset: resource.source_asset,
            name: resource.name,
            price: 0,
            quantity: 0,
            timestamp: last_available
        }
    }

    useEffect(() => {
        let market_links: string[] = [];
        const market_values = resources.map(resource => {
            const resourceKey = resource.name.toLowerCase();
            const marketInfo = marketData[resourceKey];
            market_links.push(market_name + "/" + resourceKey);
            if (marketInfo) {
                const localTimeString = dayjs.utc(marketInfo.timestamp).local().fromNow();
                if (isOlderThan(marketInfo.timestamp, market_no_longer_for_sale_metric, market_no_longer_for_sale_value)) {
                    return no_resources_available(resource, "last available " + localTimeString);
                }
                return {
                    source_asset: resource.source_asset,
                    name: resource.name,
                    price: marketInfo.price,
                    quantity: marketInfo.quantity,
                    timestamp: "available"
                }
            } else {
                // this is if the resource is not present in the response body ( for new resources mostly )
                return no_resources_available(resource, "no resources available");
            }
        })
        setMarketValues(market_values);
        setMarketLinks(market_links);
        // eslint-disable-next-line
    }, [marketData]);

    return <Container>
        <Row>
            <h3>Market</h3>
        </Row>
        <Row>
            {/*<p>*/}
            {/*    Market values where last updated {lastRequestTime}*/}
            {/*</p>*/}
            <p>
                Click on a resource to see more details
            </p>
        </Row>
        <Row>
            <MarketValueTable
                title={"Origin"}
                headers={market_headers}
                data={marketValues}
                check_for_images={true}
                links={marketLinks}
            />
        </Row>
    </Container>
}

export default GlobalMarket;
